<template>
    <div id="home">
        <heads />
        <div class="main">
            <!-- <div :class="[navdata.isThat === 0? 'zg1' : 'zg']"></div> -->
            <div ref="jpsx" class="zg1 animate__animated animate__fadeInRight" ><img src="../assets/jpsxzt.png" alt=""></div>
            <div class="tab-nav">
                <div v-for="(item, index) in navdata.data" :key="index" :class="['tab-item', index == navdata.isThat && 'tab-active']" @click="chooseThis(index)">{{item}}</div>
                <!-- <img src="../assets/dcjz.png" class="dcjz" alt=""> -->
            </div>
            <div class="appreciate" v-if="navdata.isThat == 0">
                <div v-for="(item, index) in yearData.show" :key="'n' + index" :class="['appreciate-item-pst', 'appreciate-pst-' + index, item.className === 'haves'? 'zz-box' : '']" @mouseover="noZZP(index)" @mouseleave="haveZZP(index)" @click="goThisYearZ(index)">
                    <div>
                        <img class="appreciate-pst-img" :src="item.image" style="" alt="">
                    </div>
                    <div class="appreciate-pst-txt">{{item.title}}</div>
                </div>
                <div v-for="(item, index) in yearData.data" :key="'m' + index" :class="['appreciate-item', 'appreciate-item-child' + index]" @click="goThisYear(item)" @mouseover="noZZ(index)" @mouseleave="haveZZ(index)">
                    <div :class="['appreciate-item-des', 'appreciate-item-' + index, item.year && 'isyear', item.hasClass === 'has'? 'appreciate-item-des-ss' : '']">
                        <div v-if="item.year" class="set-year"><img src="../assets/air.png" class="air-img" alt="">
                            <div class="setyearpst">
                                <div>{{item.year.split('-')[0]}}</div>
                                <div style="font-size: 18px;margin-left: 4px">&nbsp;|&nbsp;</div>
                                <div>{{item.year.split('-')[1]}}</div>
                            </div>
                        </div>
                        <div v-if="item.tips" class="appreciate-tips">
                            <div v-show="item.tips.indexOf('-') != -1">
                                <div>{{item.tips.split('-')[0]}}</div>
                                <span>&nbsp;|&nbsp;</span>
                                <div>{{item.tips.split('-')[1]}}</div>
                            </div>
                            <div v-show="item.tips.indexOf('-') == -1">
                                {{item.tips}}
                            </div>
                        </div>
                        <div class="ap-txt">
                            {{item.title}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="celebrity" v-else>
                <div class="lsrwcx">
                    <div style="width: 156px; text-align:right;">
                        查询：
                    </div>
                    <a-select v-model="selectValue" @change="handleChange" style="width: 160px" placeholder="历史人物查询">
                        <a-select-option v-for="item in selectArr" :key="item.id" :value="item.itemText">{{item.itemText}}</a-select-option>
                    </a-select>
                </div>
                <img src="../assets/celebrity.png" alt="">
                <div class="respect respect1" @click="goThisPerson('周恩来')">
                    <img :src="require('../assets/zhouenlai.png')" alt="">
                </div>
                <div class="respect respect2" @click="goThisPerson('何叔衡')">
                    <img :src="require('../assets/heshuheng.png')" alt="">
                </div>
                <div class="respect respect3" @click="goThisPerson('杨连娣')">
                    <img :src="require('../assets/yangliandi.png')" alt="">
                </div>
                <div class="respect respect4" @click="goThisPerson('毛泽东')">
                    <img :src="require('../assets/maozedong.png')" alt="">
                </div>
                <div class="respect respect5" @click="goThisPerson('于方舟')">
                    <img :src="require('../assets/yufangzhou.jpg')" alt="">
                </div>
                <div class="respect respect6" @click="goThisPerson('朱德')">
                    <img :src="require('../assets/zhude.png')" alt="">
                </div>
            </div>
        </div>
        <foots />
    </div>
</template>

<script>
import heads from './component/heads.vue'
import foots from './component/foot.vue';
import 'animate.css';
export default {
    name: 'home',
    components: {heads, foots},
    data() {
        return {
            navdata: {
                data: ['藏品鉴赏', '名人遗物'],
                isThat: 0
            },
            yearData: {
                data: [
                    {hasClass: 'no', tips: '清', title: '十月革命一声炮响，给中国送来了马克思主义。'},
                    {hasClass: 'no', year: '1912-1919', title: ''},
                    {hasClass: 'no', year: '1921-1924', title: '重点展品有1920年周恩来在《新约全书》上给入狱代表孟震侯的题字；吉鸿昌就义前写的遗嘱；群众出版社印行的抗日军政大学讲义之一毛泽东著《唯物论辩证法》；'},
                    {hasClass: 'no', tips: '1924-1937', title: ''},
                    {hasClass: 'no', year: '1937-1945', title: '1945年日寇投降后天津工作委员会吴砚农同志在霸县石沟工作时用的煤油灯等'},
                    {hasClass: 'no', year: '1945-1949', title: '1949年华东军区淮海战役纪念章等'},
                    {hasClass: 'no', year: '1949-', title: '抗美援朝国际医防十五队三等集体功锦旗'},
                ],
                show: [
                    {
                        title: '五四运动以磅礴之力鼓动了中国人民和中华民族实现民族复兴的志向和信心。',
                        image: require('../assets/01.png'),
                        className: ''
                    },{
                        title: '第二次国内革命战争时期牺牲的何叔衡烈士遗物——罗大褂 ',
                        image: require('../assets/06.png'),
                        className: ''
                    },{
                        title: '第二次国内革命战争时期牺牲的何叔衡烈士遗物——怀表。',
                        image: require('../assets/02.png'),
                        className: ''
                    },{
                        title: '毛主席在开国大典时穿过的绿呢制服',
                        image: require('../assets/05.png'),
                        className: ''
                    },{
                        title: '天津试制成功的第一只国产手表',
                        image: require('../assets/03.png'),
                        className: ''
                    },{
                        title: '中国人民志愿军一级战斗英雄杨连弟在抗美援朝战场使用的军用工具挎包；',
                        image: require('../assets/04.png'),
                        className: ''
                    }
                ]
            },
            selectValue: '',
            selectArr: []
        }
    },
    mounted() {
        setTimeout(()=>{
            console.log(this.$refs.jpsx.className="zg2")
        },3000)
        this.getInfo();
    },
    methods: {
        // 导航切换
        chooseThis(index) {
            console.log(2222)
            this.navdata.isThat = index;
        },
        // 选择年份
        goThisYear(data) {
            console.log(data)
            if(data.year) {
                console.log(data.year);
                this.$router.push({path: '/list', query: {type: 'year', opt: data.year}});
            } else {
                console.log(data.tips);
                this.$router.push({path: '/list', query: {type: 'year', opt: data.tips}});
            }
        },
        // 选择人物
        goThisPerson(data) {
            console.log(data);
            this.$router.push({path: '/list', query: {type: 'person', opt: data}});
        },
        // 鼠标悬浮
        noZZ(i) {
            // if (i === 0) return;
            // if (i === 5) {
            //     this.yearData.show[5].className = 'haves';
            // } else if (i === 6) {
            //     this.yearData.show[4].className = 'haves';
            // } else {
            //     this.yearData.show[i - 1].className = 'haves';
            // }
            // if (i < 4)
            //     this.yearData.show[i - 1].className = 'haves';
            this.yearData.data[i].hasClass = 'has';
        },
        haveZZ(i) {
            // if (i === 0) return;
            // if (i === 5) {
            //     this.yearData.show[5].className = 'nos';
            // } else if (i === 6) {
            //     this.yearData.show[4].className = 'nos';
            // } else {
            //     this.yearData.show[i - 1].className = 'nos';
            // }
            // if (i < 4)
            //     this.yearData.show[i - 1].className = 'nos';
            this.yearData.data[i].hasClass = 'no';
        },
        noZZP(i) {
            // if (i === 4) 
            //     this.yearData.data[6].hasClass = 'has';
            // else if (i === 5) 
            //     this.yearData.data[5].hasClass = 'has';
            // else 
            // if (i < 3)
            //     this.yearData.data[i + 1].hasClass = 'has';
            this.yearData.show[i].className = 'haves';
        },
        haveZZP(i) {
            // if (i === 4) 
            //     this.yearData.data[6].hasClass = 'no';
            // else if (i === 5) 
            //     this.yearData.data[5].hasClass = 'no';
            // else 
            // if (i < 3)
            //     this.yearData.data[i + 1].hasClass = 'no';
            this.yearData.show[i].className = 'nos';
        },
        // 跳转页面
        goThisYearZ(i) {
            console.log(i);
            // let data;
            // if (i === 4) 
            //     data = this.yearData.data[6];
            // else if (i === 5) 
            //     data = this.yearData.data[5];
            // else 
            // if (i < 3)
            //     data = this.yearData.data[i + 1];
            // this.goThisYear(data);
            this.$router.push({path: '/picPage', query: {type: i}});
        },
        // 历史人物事件
        handleChange(val) {
            this.goThisPerson(val);
        },
        // 任务字典
        getInfo() {
            if (this.$route.query && this.$route.query.point === '2') {
                this.chooseThis(1);
            }
            if (this.selectArr.length === 0) {
                this.$axios.get('/sys/dictItem/list?dictId=1517382442367856641').then(res => {
                    this.selectArr = res.result.records;
                })
            }
            
        }
    }
}
</script>

<style scoped>
    #home {
        height: 100%;
        min-width: 1903px;
    }
    .main {
        background: url('../assets/jpsx.png') no-repeat;
        background-position-x: 3%;
        padding-top: 30px;
        padding-left: 14%;
        position: relative;
    }
    .zg1 {
        width: 217px;
        height: 250px;
        /* line-height: 200px; */
        font-size: 50px;
        text-align: center;
        font-family: "字酷堂清楷体";
        color:#fff;
        position: absolute;
        z-index: 10;
        left:2.7%;
        top: 6%;
    }
    .zg2 {
        width: 217px;
        height: 250px;
        /* line-height: 200px; */
        font-size: 50px;
        text-align: center;
        font-family: "字酷堂清楷体";
        color:#fff;
        position: absolute;
        z-index: 10;
        left:2.7%;
        top: 6%;
        animation: pulse 5s  infinite;
    }
    /* tab */
    .tab-nav {
        display: flex;
        height: 80px;
        line-height: 80px;
        font-size: 30px;
        font-weight: 600;
        color: #000;
    }
    .tab-item {
        padding: 0 30px;
        cursor: pointer;
        margin-right: 6px;
    }
    .tab-active {
        color: #790000;
        border-bottom: 4px solid #790000;
        box-sizing: border-box;
    }
    .dcjz {
        position: absolute;
        right: 6%;
    }
    .celebrity img {
        width: 100%;
    }

    .appreciate {
        border-bottom: 30px solid #790000;
        display: flex;
        position: relative;
        background: url('../assets/bg1.png') no-repeat;
        background-position-y: 10px;
        background-position-x: 80%;
        padding: 20px 4% 0;
        font-family: 'NSimSun';
        font-weight: 600;
        color: #000;
        min-height: 937px;
    }
    .appreciate-item {
        position: relative;
        cursor: pointer;
        z-index: 2;
        min-width: 92px;
    }
    .appreciate-item-child0 {
        width: 4%;
        min-width: 58px;
    }
    .appreciate-item-child1 {
        width: 7.8%;
        margin-left: 1.6%;
    }
    .appreciate-item-child2 {
        width: 8.2%;
        margin-left: 5%;
    }
    .appreciate-item-child3 {
        width: 12%;
        margin-left: 5%;
    }
    .appreciate-item-child4 {
        width: 7%;
        margin-left: 31%;
    }
    .appreciate-item-child5 {
        width: 7%;
        margin-left: 1%;
    }
    .appreciate-item-child6 {
        width: 8.2%;
        margin-left: 1%;
    }
    .appreciate-tips {
        position: absolute;
        padding: 1px 10px;
        color: #fff;
        background: #790000;
        top: 0;
        right: 0;
    }
    .air-img {
        margin-right: 10px;
        vertical-align: bottom;
    }
    .set-year {
        width: 100%;
        height: 22px;
        line-height: 22px;
        font-size: 26px;
        color: #790000;
        position: absolute;
        top: -26px;
        left: -12px;
    }
    .isyear {
        border-left: 2px dashed #790000;
        padding-left: 10px;
    }
    .appreciate-item-pst {
        position: absolute;
        z-index: 5;
    }
    .appreciate-item-des {
        position: absolute;
        box-sizing: border-box;
    }
    .ap-txt {
        font-family:'Microsoft YaHei';
        font-weight: 300;
        letter-spacing: 3px;
    }
    .appreciate-item-des .setyearpst {
        transition: all .5s;
    }
    .appreciate-item-des .appreciate-tips {
        transition: all .5s;
    }
    .appreciate-item-des .set-year img {
        animation: zhuan 5s infinite linear;
        transform: rotate(360deg);
    }
    @keyframes zhuan {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
    .appreciate-item-des-ss .setyearpst {
        color: #000;
        transform: scale(1.1);
    }
    .appreciate-item-des-ss {
        color: #790000;
    }
    .appreciate-item-des-ss .appreciate-tips {
        font-weight: 900;
        transform: scale(1.5);
    }
    .appreciate-item-child0:hover .appreciate-tips {
        font-weight: 900;
        transform: scale(1.5);
    }
    .appreciate-item-child0:hover {
        color: #790000;
    }
    .appreciate-item-0 {
        bottom: 0%;
        padding-right: 16%;
        padding-top: 60px;
        border-right: 4px solid #790000;
        height: 440px;
    }
    .appreciate-item-1 {
        width: 100%;
        height: 500px;
        bottom: 0%;
    }
    .appreciate-item-2 {
        height: 86%;
        padding-top: 40%;
        bottom: 0%;
    }
    .appreciate-item-3 {
        height: 400px;
        bottom: 0;
        width: 100%;
        border-left: 4px solid #790000;
    }
    .appreciate-item-3 .appreciate-tips {
        left: -50px;
        width: 50px;
    }
    .appreciate-item-4 {
        bottom: 0;
        height: 300px;
        padding-top: 46%;
    }
    .appreciate-item-5 {
        bottom: 0;
        height: 360px;
        padding-top: 46%;
    }
    .appreciate-item-6 {
        bottom: 0;
        height: 280px;
        padding-top: 26%;
    }
    .appreciate-item-7 {
        bottom: 0;
        height: 400px;
    }
    .appreciate-pst-txt {
        /* color: #9a856c; */
        font-family:'Microsoft YaHei';
        font-weight: 300;
        letter-spacing: 3px;
    }
    .appreciate-pst-0 {
        width: 104px;
        bottom: 14%;
        left: 10%;
    }
    .appreciate-pst-0 .appreciate-pst-img {
        margin-bottom: 10px;
    }
    .appreciate-pst-1 {
        left: 16%;
        top: 49.8%;
        /* z-index: -1; */
    }
    .appreciate-pst-1 .appreciate-pst-txt {
        width: 120px;
        position: absolute;
        left: 34%;
        margin-top: 20px;
    }
    .appreciate-pst-2 {
        bottom: 10%;
        left: 36%;
    }
    .appreciate-pst-2::after {
        content: '';
        height: 47%;
        border-left: 4px solid #790000;
        position: absolute;
        left: 49%;
        bottom: -53%;
    }
    .appreciate-pst-2 .appreciate-pst-txt {
        position: absolute;
        width: 62px;
        top: -65%;
        left: -22%;
    }
    .appreciate-pst-3 {
        left: 39%;
        top: 23%;
    }
    .appreciate-pst-3::before {
        content: '';
        border-left: 4px solid #790000;
        position: absolute;
        height: 50px;
        top: -54px;
        left: 45%;
    }
    .appreciate-pst-3::after {
        content: '';
        border-left: 4px solid #790000;
        position: absolute;
        height: 470px;
        bottom: -484px;
        left: 45%;
    }
    .appreciate-pst-3 .appreciate-pst-txt {
        width: 120px;
        position: absolute;
        top: -26%;
        right: -8%;
    }
    .appreciate-pst-4 {
        left: 64%;
        bottom: 240px;
    }
    .appreciate-pst-4 .appreciate-pst-txt {
        position: absolute;
        top: 0;
        width: 90px;
        left: -130%;
    }
    .appreciate-pst-5 {
        left: 55%;
        bottom: 0;
    }
    .appreciate-pst-5::after {
        content: '';
        position: absolute;
        height: 250px;
        border-left: 2px solid #790000;
        left: -10%;
        top: -43px;
    }
    .appreciate-pst-5 .appreciate-pst-txt {
        width: 180px;
        position: absolute;
        top: -110px;
        left: -6%;
    }
     
    .appreciate-item-pst img,
    .appreciate-item-pst .appreciate-pst-txt {
        transition: transform 1s;
    }
    /* 名人遗物 */
    .celebrity {
        position: relative;
    }
    .respect {
        position: absolute;
        cursor: pointer;
    }
    .respect1 {
        top: 26%;
        left: 25%;
        width: 18%;
        height: 43%;
    }
    .respect2 {
        top: 26%;
        left: 44%;
        width: 15%;
        height: 26%;
    }
    .respect3 {
        top: 21%;
        right: 18%;
        width: 16%;
        height: 28%;
    }
    .respect4 {
        top: 57%;
        left: 37%;
        width: 22%;
        height: 41%;
    }
    .respect5 {
        top: 53%;
        right: 6%;
        width: 29%;
        height: 38%;
    }
    .respect6 {
        top: 42%;
        left: 55%;
        width: 19%;
        height: 32%;
    }
    .setyearpst {
        position: absolute;
        left: 25px;
        top: 0px;
    }
    .respect1 img {
        position: absolute;
        width: 30px;
        top: 1%;
    }
    .respect2 img {
        position: absolute;
        width: 30px;
        top: -3%;
    }
    .respect3 img {
        position: absolute;
        width: 30px;
        top: 12%;
    }
    .respect4 img {
        position: absolute;
        width: 30px;
        top: 26%;
        left: 5%;
    }
    .respect5 img {
        position: absolute;
        width: 30px;
        top: 54%;
        left: 2%;
    }
    .respect6 img {
        position: absolute;
        width: 30px;
        top: 40%;
    }
    .respect:hover img {
        transform: scale(1.1);
    }
    .appreciate-item-pst img {
        -webkit-filter: grayscale(.95);
        filter: grayscale(.95)
    }
    .zz-box img {
        transform: scale(1.1);
        -webkit-filter: grayscale(0);
        filter: grayscale(0)
    }
    .zz-box .appreciate-pst-txt {
        color: #790000;
    }
    .tab-item:hover {
        color: #790000;
    }
    .lsrwcx {
        position: absolute;
        display: flex;
        /* right: 6%;
        top: 120px; */
        left: 0%;
        top: 80px;
        line-height: 32px;
        font-size: 20px;
        font-weight: 600;
    }
    .appreciate-item-child3 {
        z-index: 6;
    }
    .appreciate-pst-2 {
        z-index: 7;
    }
    .ant-select-dropdown-menu-item {
        font-size: 18px;
    }
</style>
